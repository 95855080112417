<template>
  <div class="wrapper">
    <KeepAlive>
      <FormStep
        v-if="step === 'form'"
        @success="step = 'success'"
        @logout="step = 'logout'"
      />
      <LogoutStep v-else-if="step === 'logout'" @back="step = 'form'" />
      <SuccessStep v-else-if="step === 'success'" />
    </KeepAlive>
  </div>
</template>

<script setup lang="ts">
import FormStep from './parts/FormStep.vue'
import LogoutStep from './parts/LogoutStep.vue'
import SuccessStep from './parts/SuccessStep.vue'

const step = ref<'form' | 'logout' | 'success'>('form')
</script>
