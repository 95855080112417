<template>
  <div class="success-step" :class="{ mobile: isMobile }" data-t="success-step">
    <div class="content">
      <StIcon
        class="card"
        name="gift-solid"
        color="var(--icon-primary)"
        :size="isMobile ? 52 : 64"
      />
      <div class="text">
        <h2 class="title">
          {{ t('userVerificationModal.congratulations') }}
        </h2>
        <p class="subtitle">
          {{ t('userVerificationModal.youGotWelcomeBonus') }}
        </p>
      </div>
    </div>
    <div class="actions">
      <p class="caption">
        <StIcon name="circle-clock-solid" :size="isMobile ? 12 : 16" />
        {{ t('userVerificationModal.limitedTimeToUseBonuses') }}
      </p>
      <StButton
        is-left-icon
        to="/bonuses"
        replace
        icon="play-solid"
        :label="t('userVerificationModal.play')"
        :size="isMobile ? 'l' : 'xl'"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const { isMobile } = usePlatform()

const { t } = useI18n()
</script>

<style scoped>
.success-step {
  user-select: none;

  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 480px;

  background: var(--background-base);
  border-radius: var(--border-radius-200);

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 236px;

    background-image: url('../images/green.jpg');
    background-size: contain;
    border-radius: var(--border-radius-200, 16px) var(--border-radius-200, 16px)
      var(--border-radius-0, 0) var(--border-radius-0, 0);
  }

  &.mobile {
    width: auto;
  }
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-300) var(--spacing-200) 0;
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: center;
}

.title {
  position: relative;

  margin: 0;

  font: var(--desktop-text-xl-semibold);
  color: var(--text-primary);
  text-align: center;
  text-wrap: balance;
}

.mobile .title {
  font: var(--mobile-title-1-semibold);
}

.subtitle {
  margin: 0;

  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);
  text-align: center;
  text-wrap: balance;
}

.mobile .subtitle {
  font: var(--mobile-text-content-regular);
}

.actions {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-150);

  padding: var(--spacing-300);
}

.caption {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: center;

  margin: 0;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}
</style>
